<template>
  <div id="common">
    <div class="page" size="A4">
      <v-row class="pt-2">
        <v-col
          style="line-height: 12pt; margin-top: 15px; padding-left: 25px"
          class="d-flex justify-center align-center"
        >
          <div>
            <v-avatar size="75">
              <img
                src="https://links.casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
              />
            </v-avatar>
          </div>
          <div>
            <div>
              <b style="font-size: 20px">Rede Casas das Tintas</b>
              <div v-if="hasAddress">
                {{ bill_receivable.company.main_address.address }}
                {{ bill_receivable.company.main_address.number }},
                {{ bill_receivable.company.main_address.district }},
                {{ bill_receivable.company.main_address.city }} -
                {{ bill_receivable.company.main_address.state }},
                {{
                  $format.cep(bill_receivable.company.main_address.postal_code)
                }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <span class="mr-2">Tel:</span>
              <div v-for="(phone, index) in phones" :key="index">
                {{ phone }}

                <span class="mr-1" v-if="phones.length > index + 1">,</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="text-center">
        <v-col> <h1>Vale Crédito</h1> </v-col>
      </v-row>

      <v-row class="text-center">
        <v-col>
          <h3>
            Cliente:
            {{ bill_receivable.customer.code }} -
            {{ bill_receivable.customer.name }}
            <br />
            Valor:
            {{ $format.moneyBr(creditValue) }}
            ({{ $format.extenso(creditValue) }})
          </h3>
        </v-col>
      </v-row>

      <v-row class="text-center" v-if="bill_receivable.description">
        <v-col> Descrição: {{ bill_receivable.description }} </v-col>
      </v-row>

      <v-row class="d-flex justify-center text-center mb-15 mt-8">
        <v-col>
          <span v-if="hasAddress">
            {{ bill_receivable.company.main_address.city }},
          </span>
          {{ issueDate }}
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center text-center">
        <v-col cols="6">
          <hr />
          Assinatura
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bill_receivable: {
        customer: {},
        company: {
          main_address: {},
          phones: [],
        },
      },
    };
  },

  async created() {
    this.$loading.start();

    await this.$http
      .show("bill-receivable/bill-receivable", this.$route.params.id)
      .then((response) => {
        this.bill_receivable = response.bill_receivable;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    creditValue() {
      return this.bill_receivable.value * -1;
    },

    issueDate() {
      return this.$format.dateBr(
        this.bill_receivable.issue_date,
        "dd 'de'  LLLL 'de' Y"
      );
    },
    hasAddress() {
      return this.bill_receivable.company.main_address != null;
    },
    hasPhones() {
      return this.bill_receivable.company.phones.length > 0;
    },
    phones() {
      return this.bill_receivable.company.phones.map((phone) => phone.contact);
    },
  },
};
</script>

<style  lang="scss">
hr {
  border: 1px solid black;
}
#common {
  .page {
    width: 21cm;
    min-height: 15cm;
    padding-top: 0.1cm;
    padding-left: 0.3cm;
    padding-right: 0.3cm;
    margin: 0.3cm;
    border: 1px #d3d3d3 solid;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  div.col {
    padding-top: 0.4%;
    padding-bottom: 0.4%;
  }

  @page {
    size: A4;
    margin: 0.5cm;
    padding: 0.5cm;
  }

  @media print {
    .page {
      visibility: visible;
      margin: initial;
      border: rgba(0, 0, 0, 0.1);
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
      size: landscape;
    }
  }
}
</style>